import React from 'react'
import { useState } from 'react'
import './Header.css'
import {BiMenuAltRight} from 'react-icons/bi'
import OutsideClickHandler from 'react-outside-click-handler'
function Header() {

  const [menuOpened,setMenuOpen]=React.useState(false)
  const getMenuStyles=(menuOpened)=>{
    if(document.documentElement.clientWidth<=800){
      return {right:!menuOpened && "-100%"}
    }

  }
  return (

    <section className='h-wrapper'>
        <div className='h-container flexCenter paddings innerWidth'>
            <img src='./logo.png' alt="logo" width={100} />
          <OutsideClickHandler
          onOutsideClick={()=>{
            setMenuOpen(false)
          }}
          >
            <div className='flexCenter h-menu'
            style={getMenuStyles(menuOpened)}
            
            >
            <a href="">Residencies</a>
            <a href="">Our value</a>
            <a href="">Contact US</a>
            <a href="">Get Started</a>
            <button className='button'>
            <a href="">Contact</a>
            </button>
            </div>
            </OutsideClickHandler>
            <div className='menu-icon' onClick={()=>setMenuOpen((prev)=>!prev)}>
      <BiMenuAltRight size={30} />
    </div>
        </div>

   


    </section>
  )
}

export default Header
